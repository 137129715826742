/* eslint-disable max-lines */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/prop-types */
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { legalPageRoutes, tabletRoutes } from '@utils/constants';
import { segment } from '@actions/analytics';
import { REFERRAL_PLAYER_POPUP } from '@constants/segment';
import { showTTUOption } from '@api/fetchers/ttu';
import { breakpoints, Spacings } from '@styles/index';
import {
  getTTUFlag,
  getTTUShow,
  getShowTTUCurtain
} from '@stores/selectors/ttuSelectors';
import Dynamic from '@base/Dynamic/Dynamic';
import { getMe } from '@api/selectors/auth';
import { useToggleable } from '@stores/toggleables';
import { LOGIN_DRAWER } from '@constants/toggleables';
import { getShowReferralDialog } from '@stores/selectors/playerReferralDialog';
import usePlayerReferralDialogStore from '@stores/playerReferralDialog';
import useTTUStore from '@stores/ttu';
import { useIsMobile } from '@stores/AppCommonData';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import useSelectedGoal from '@api/hooks/topology/useSelectedGoal';
import useLoginModalExperiment from '@cont/Login/utils/hooks/useLoginModalExperiment';
import LoginComponent from '@cont/Login/LoginComponent';
import useShowLoginDrawerOnBatch from '@stores/showLoginDrawerOnBatch';
import deviceHelper from '@utils/deviceHelper';
import Header from './Header/Header';
import PageContent from './Components/Content';
import ParentContainer from './Components/AppContainer';
import BaseFooter from './Footer/BaseFooter';
import LayoutContainer from './Components/Container';
import PlusNavBar from './NavBar/PlusNavBar/PlusNavBar';

const ClassVideoPlayer = Dynamic(() =>
  import('@cont/ClassVideoPlayer/ClassVideoPlayer')
);
const TTUWidgetRenderer = Dynamic(() => import('./TTUWidgetRenderer'));
const TTURenderer = Dynamic(() => import('./TTURenderer'));
const ReferralPopup = Dynamic(() => import('./ReferralPopup'));

const Container = styled(LayoutContainer)`
  @media only screen and (max-width: ${breakpoints.tablet}) {
    min-width: 0px;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: 100vw;
  }
`;

const NavbarWrapper = styled.div`
  position: relative;
  grid-column: 1/7;
  z-index: 2;
  height: 100%;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

const EduNavBar = Dynamic(() => import('./NavBar/EduNavBar'));

const AppContainer = styled(ParentContainer)`
  margin-top: 0;
  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    display: grid;
    grid-column-gap: ${Spacings.SPACING_2B};
    grid-row-gap: 0;
    grid-template-columns: repeat(10, 1fr);
  }
`;

const Content = styled(PageContent)`
  margin-top: ${({ fullHeight }) => (fullHeight ? '0' : '40px')};
  grid-column: ${({ fullWidth }) => (fullWidth ? 1 : 7)} / 25;
  grid-template-columns: repeat(
    ${({ fullWidth }) => (fullWidth ? 24 : 18)},
    32px
  );
  ${({ position }) => (position ? `position: ${position};` : '')}
  @media only screen and (max-width: ${breakpoints.tablet}) {
    margin: auto;
    width: 100%;
    grid-template-columns: repeat(
      ${({ fullWidth }) => (fullWidth ? 24 : 18)},
      1fr
    );
  }
  @media only screen and (max-width: 728px) {
    grid-template-columns: none;
    width: 100%;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    grid-column: span 10;
    grid-row-gap: 0;
    grid-column-gap: ${Spacings.SPACING_2B};
    display: block;
    padding: ${({ noMobilePadding }) =>
      noMobilePadding ? '0px' : `0 ${Spacings.SPACING_4B}`};
  }
`;

const subscribeUrl = '/goal/[goalSlug]/[goalUID]/subscribe';

const Layout = ({ children, isEducatorSide, layoutOptions = {} }) => {
  const router = useRouter();
  const { route, query } = router;
  useLoginModalExperiment();
  const me = useMyInfo(getMe);
  const { selectedGoal } = useSelectedGoal();
  const { isOpen: showLoginDrawer, closeElement: closeLoginDrawer } =
    useToggleable(LOGIN_DRAWER);
  const isMobile = useIsMobile();
  const ttuFlag = useTTUStore(getTTUFlag);
  const showTTUCurtain = useTTUStore(getShowTTUCurtain);
  const ttuShow = useTTUStore(getTTUShow);
  const showReferral = usePlayerReferralDialogStore(getShowReferralDialog);
  const [showTTU, setShowTTU] = useState(ttuShow);
  const [showTTUTooltip, setShowTTUTooltip] = useState(false);
  const [ttuTriggerSource, setTTUTriggerSource] = useState('Scroll Trigger');
  const { setShowLoginDrawer } = useShowLoginDrawerOnBatch();
  const [showReferralDialog, setShowReferralDialog] = useState(showReferral);
  const [backButton, setBackButton] = useState({
    value: false,
    fn: () => {}
  });

  const handleBackPress = () => {
    backButton.fn();
  };

  /**
   * To check whether it is called from domain other than unacademy
   */
  const isExternal = query?.external === '1';

  useEffect(() => {
    setShowReferralDialog(showReferral);
    if (showReferral) {
      segment.track(REFERRAL_PLAYER_POPUP);
    }
  }, [showReferral]);

  useEffect(() => {
    setShowTTU(ttuShow);
  }, [ttuShow]);
  const {
    hideIntercom = false,
    hideSearch: shouldHideSearch = false,
    isCheckoutUrl = false,
    hideFooter = false,
    hideHeader = false,
    showEducatorNav = false,
    showPlusNav = false,
    fullHeightContent = false,
    hideGoalSelector = false,
    noMobilePadding = false,
    useLightBaseColor = false,
    hidePlayer = false
  } = layoutOptions;
  useEffect(() => {
    if (ttuFlag && !hideIntercom && !me.isEducator && !isMobile) {
      setTimeout(() => setShowTTUTooltip(true), 15000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me.isEducator]);

  const hideSearch = shouldHideSearch || isCheckoutUrl;
  const isLoginRoute = route === '/login';

  const onClose = () => {
    setShowLoginDrawer(false, '');
    closeLoginDrawer();
  };

  const onCloseTTU = () => {
    setBackButton({ value: false, fn: () => {} });
    setShowTTU(false);
    showTTUCurtain(false);
    showTTUOption(query.goalUID || selectedGoal.uid);
  };

  function renderLogin() {
    if (!isCheckoutUrl && !isLoginRoute) {
      return (
        <LoginComponent
          loginProps={{
            setBackButton,
            backButton
          }}
          drawerProps={{
            login: true,
            drawerState: showLoginDrawer,
            onClose,
            showBackButton: backButton.value,
            hideCloseButton: backButton.value,
            onPressBack: handleBackPress
          }}
        />
      );
    }
  }

  const getBackgroundColor = () => {
    if (legalPageRoutes.includes(route)) {
      return 'var(--color-base-0)';
    }
    if (useLightBaseColor) {
      return 'var(--color-base-1)';
    }
    return null;
  };

  const insideApp = query.inside_app === 'true' || false;
  const insideIframeLogin = query.is_iframe_login === 'true' || false;

  const backgroundColor = getBackgroundColor();
  const tabletWidth = tabletRoutes.includes(route) ? '728px' : '1136px';
  const isTransparent = route === subscribeUrl;
  const isContentFullHeight = fullHeightContent || isTransparent;
  const showClassPlayer = isExternal ? true : !isMobile;

  const isMobileDevice = deviceHelper.isMobileDevice();

  if (isExternal) {
    return (
      <Container backgroundColor="var(--color-i-black-9)">
        {children}
        {showClassPlayer && <ClassVideoPlayer />}
      </Container>
    );
  }

  return (
    <Container backgroundColor={backgroundColor}>
      {!insideApp &&
        !insideIframeLogin &&
        !hideHeader &&
        !(isMobileDevice && layoutOptions?.hideHeaderOnMobile) && (
          <Header
            hideSearch={hideSearch}
            hideGoalSelector={hideGoalSelector}
            isCheckoutUrl={isCheckoutUrl}
            transparent={isTransparent}
            isLoginUrl={isLoginRoute}
          />
        )}
      <AppContainer
        backgroundColor={backgroundColor}
        transparentHeader={isTransparent}
        $tabletWidth={tabletWidth}
        $isFooterHidden={hideFooter}
      >
        {(showPlusNav || showEducatorNav) && (
          <NavbarWrapper>
            {showPlusNav && <PlusNavBar />}
            {showEducatorNav && <EduNavBar />}
          </NavbarWrapper>
        )}
        <Content
          fullHeight={isContentFullHeight}
          fullWidth={!showPlusNav && !showEducatorNav}
          noMobilePadding={noMobilePadding}
          position={
            route.indexOf('/educator/drive/folder/[itemUID]') !== -1
              ? 'relative'
              : ''
          }
        >
          {children}
        </Content>
        {!hidePlayer && showClassPlayer && <ClassVideoPlayer />}
      </AppContainer>
      {!insideApp && !insideIframeLogin && (
        <BaseFooter
          display={!hideFooter && !showPlusNav && !isEducatorSide}
          noFooter={hideFooter}
        />
      )}
      {renderLogin()}
      {!isMobile && (
        <TTURenderer
          onCloseTTU={onCloseTTU}
          showTTU={showTTU}
          backButton={backButton}
          setBackButton={setBackButton}
          handleBackPress={handleBackPress}
          ttuTriggerSource={ttuTriggerSource}
        />
      )}
      {!isMobile && (
        <TTUWidgetRenderer
          showTTU={showTTU}
          setShowTTU={setShowTTU}
          showTTUTooltip={showTTUTooltip}
          setShowTTUTooltip={setShowTTUTooltip}
          ttuTriggerSource={ttuTriggerSource}
          setTTUTriggerSource={setTTUTriggerSource}
          hideIntercom={hideIntercom}
        />
      )}
      {showReferralDialog ? (
        <ReferralPopup setShowReferralDialog={setShowReferralDialog} />
      ) : null}
    </Container>
  );
};

export default Layout;
